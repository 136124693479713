export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/artists": [3],
		"/artists/create": [5],
		"/artists/[slug]": [4],
		"/backup": [6],
		"/catalog": [7],
		"/catalog/[slug]": [8],
		"/catalog/[slug]/offer-confirmation": [10],
		"/catalog/[slug]/offer-history": [11],
		"/catalog/[slug]/offer": [9],
		"/delegate-account": [12],
		"/link-device": [13],
		"/recover": [14],
		"/register": [15],
		"/settings": [16],
		"/works": [17]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';